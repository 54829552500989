// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-article-index-jsx": () => import("./../../../src/pages/article/index.jsx" /* webpackChunkName: "component---src-pages-article-index-jsx" */),
  "component---src-pages-awards-and-recognitions-jsx": () => import("./../../../src/pages/awards-and-recognitions.jsx" /* webpackChunkName: "component---src-pages-awards-and-recognitions-jsx" */),
  "component---src-pages-client-story-index-jsx": () => import("./../../../src/pages/client-story/index.jsx" /* webpackChunkName: "component---src-pages-client-story-index-jsx" */),
  "component---src-pages-clutch-jsx": () => import("./../../../src/pages/clutch.jsx" /* webpackChunkName: "component---src-pages-clutch-jsx" */),
  "component---src-pages-code-ux-audit-jsx": () => import("./../../../src/pages/code-ux-audit.jsx" /* webpackChunkName: "component---src-pages-code-ux-audit-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-data-engineering-jsx": () => import("./../../../src/pages/data-engineering.jsx" /* webpackChunkName: "component---src-pages-data-engineering-jsx" */),
  "component---src-pages-dedicated-team-jsx": () => import("./../../../src/pages/dedicated-team.jsx" /* webpackChunkName: "component---src-pages-dedicated-team-jsx" */),
  "component---src-pages-digiryte-pods-jsx": () => import("./../../../src/pages/digiryte-pods.jsx" /* webpackChunkName: "component---src-pages-digiryte-pods-jsx" */),
  "component---src-pages-digital-transformation-jsx": () => import("./../../../src/pages/digital-transformation.jsx" /* webpackChunkName: "component---src-pages-digital-transformation-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-financial-crime-compliance-automation-jsx": () => import("./../../../src/pages/financial-crime-compliance-automation.jsx" /* webpackChunkName: "component---src-pages-financial-crime-compliance-automation-jsx" */),
  "component---src-pages-full-product-development-jsx": () => import("./../../../src/pages/full-product-development.jsx" /* webpackChunkName: "component---src-pages-full-product-development-jsx" */),
  "component---src-pages-hire-remote-teams-jsx": () => import("./../../../src/pages/hire-remote-teams.jsx" /* webpackChunkName: "component---src-pages-hire-remote-teams-jsx" */),
  "component---src-pages-how-we-do-it-jsx": () => import("./../../../src/pages/how-we-do-it.jsx" /* webpackChunkName: "component---src-pages-how-we-do-it-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-india-jsx": () => import("./../../../src/pages/india.jsx" /* webpackChunkName: "component---src-pages-india-jsx" */),
  "component---src-pages-insights-articles-jsx": () => import("./../../../src/pages/insights/articles.jsx" /* webpackChunkName: "component---src-pages-insights-articles-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-insights-podcast-jsx": () => import("./../../../src/pages/insights/podcast.jsx" /* webpackChunkName: "component---src-pages-insights-podcast-jsx" */),
  "component---src-pages-insights-white-papers-jsx": () => import("./../../../src/pages/insights/white-papers.jsx" /* webpackChunkName: "component---src-pages-insights-white-papers-jsx" */),
  "component---src-pages-landing-page-index-jsx": () => import("./../../../src/pages/landing-page/index.jsx" /* webpackChunkName: "component---src-pages-landing-page-index-jsx" */),
  "component---src-pages-landing-page-offer-jsx": () => import("./../../../src/pages/landing-page/offer.jsx" /* webpackChunkName: "component---src-pages-landing-page-offer-jsx" */),
  "component---src-pages-landing-page-thank-you-jsx": () => import("./../../../src/pages/landing-page/thank-you.jsx" /* webpackChunkName: "component---src-pages-landing-page-thank-you-jsx" */),
  "component---src-pages-locations-bangalore-jsx": () => import("./../../../src/pages/locations/bangalore.jsx" /* webpackChunkName: "component---src-pages-locations-bangalore-jsx" */),
  "component---src-pages-locations-chennai-jsx": () => import("./../../../src/pages/locations/chennai.jsx" /* webpackChunkName: "component---src-pages-locations-chennai-jsx" */),
  "component---src-pages-locations-coimbatore-jsx": () => import("./../../../src/pages/locations/coimbatore.jsx" /* webpackChunkName: "component---src-pages-locations-coimbatore-jsx" */),
  "component---src-pages-locations-hyderabad-jsx": () => import("./../../../src/pages/locations/hyderabad.jsx" /* webpackChunkName: "component---src-pages-locations-hyderabad-jsx" */),
  "component---src-pages-locations-manchestor-jsx": () => import("./../../../src/pages/locations/manchestor.jsx" /* webpackChunkName: "component---src-pages-locations-manchestor-jsx" */),
  "component---src-pages-locations-mumbai-jsx": () => import("./../../../src/pages/locations/mumbai.jsx" /* webpackChunkName: "component---src-pages-locations-mumbai-jsx" */),
  "component---src-pages-machine-learning-jsx": () => import("./../../../src/pages/machine-learning.jsx" /* webpackChunkName: "component---src-pages-machine-learning-jsx" */),
  "component---src-pages-mobile-application-development-jsx": () => import("./../../../src/pages/mobile-application-development.jsx" /* webpackChunkName: "component---src-pages-mobile-application-development-jsx" */),
  "component---src-pages-mvp-development-jsx": () => import("./../../../src/pages/mvp-development.jsx" /* webpackChunkName: "component---src-pages-mvp-development-jsx" */),
  "component---src-pages-our-pod-model-jsx": () => import("./../../../src/pages/our-pod-model.jsx" /* webpackChunkName: "component---src-pages-our-pod-model-jsx" */),
  "component---src-pages-podcast-index-jsx": () => import("./../../../src/pages/podcast/index.jsx" /* webpackChunkName: "component---src-pages-podcast-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-designing-and-prototyping-jsx": () => import("./../../../src/pages/product-designing-and-prototyping.jsx" /* webpackChunkName: "component---src-pages-product-designing-and-prototyping-jsx" */),
  "component---src-pages-product-development-jsx": () => import("./../../../src/pages/product-development.jsx" /* webpackChunkName: "component---src-pages-product-development-jsx" */),
  "component---src-pages-product-scaling-jsx": () => import("./../../../src/pages/product-scaling.jsx" /* webpackChunkName: "component---src-pages-product-scaling-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-team-augmentation-jsx": () => import("./../../../src/pages/team-augmentation.jsx" /* webpackChunkName: "component---src-pages-team-augmentation-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */),
  "component---src-pages-terms-and-conditions-v-1-1-jsx": () => import("./../../../src/pages/terms-and-conditions/v1.1.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-v-1-1-jsx" */),
  "component---src-pages-terms-and-conditions-v-1-2-jsx": () => import("./../../../src/pages/terms-and-conditions/v1.2.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-v-1-2-jsx" */),
  "component---src-pages-terms-and-conditions-v-1-3-jsx": () => import("./../../../src/pages/terms-and-conditions/v1.3.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-v-1-3-jsx" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thank-you-client-story-jsx": () => import("./../../../src/pages/thank-you-client-story.jsx" /* webpackChunkName: "component---src-pages-thank-you-client-story-jsx" */),
  "component---src-pages-thank-you-contact-clutch-jsx": () => import("./../../../src/pages/thank-you-contact-clutch.jsx" /* webpackChunkName: "component---src-pages-thank-you-contact-clutch-jsx" */),
  "component---src-pages-thank-you-contact-jsx": () => import("./../../../src/pages/thank-you-contact.jsx" /* webpackChunkName: "component---src-pages-thank-you-contact-jsx" */),
  "component---src-pages-thank-you-white-paper-jsx": () => import("./../../../src/pages/thank-you-white-paper.jsx" /* webpackChunkName: "component---src-pages-thank-you-white-paper-jsx" */),
  "component---src-pages-web-application-development-jsx": () => import("./../../../src/pages/web-application-development.jsx" /* webpackChunkName: "component---src-pages-web-application-development-jsx" */),
  "component---src-pages-what-we-do-jsx": () => import("./../../../src/pages/what-we-do.jsx" /* webpackChunkName: "component---src-pages-what-we-do-jsx" */),
  "component---src-pages-white-paper-index-jsx": () => import("./../../../src/pages/white-paper/index.jsx" /* webpackChunkName: "component---src-pages-white-paper-index-jsx" */),
  "component---src-pages-who-we-work-with-education-jsx": () => import("./../../../src/pages/who-we-work-with/education.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-education-jsx" */),
  "component---src-pages-who-we-work-with-finance-jsx": () => import("./../../../src/pages/who-we-work-with/finance.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-finance-jsx" */),
  "component---src-pages-who-we-work-with-healthcare-jsx": () => import("./../../../src/pages/who-we-work-with/healthcare.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-healthcare-jsx" */),
  "component---src-pages-who-we-work-with-hospitality-jsx": () => import("./../../../src/pages/who-we-work-with/hospitality.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-hospitality-jsx" */),
  "component---src-pages-who-we-work-with-jsx": () => import("./../../../src/pages/who-we-work-with.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-jsx" */),
  "component---src-pages-who-we-work-with-media-and-publishing-jsx": () => import("./../../../src/pages/who-we-work-with/media-and-publishing.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-media-and-publishing-jsx" */),
  "component---src-pages-who-we-work-with-not-for-profit-jsx": () => import("./../../../src/pages/who-we-work-with/not-for-profit.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-not-for-profit-jsx" */),
  "component---src-pages-who-we-work-with-public-sector-jsx": () => import("./../../../src/pages/who-we-work-with/public-sector.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-public-sector-jsx" */),
  "component---src-pages-who-we-work-with-real-estate-and-proptech-jsx": () => import("./../../../src/pages/who-we-work-with/Real-estate-and-proptech.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-real-estate-and-proptech-jsx" */),
  "component---src-pages-who-we-work-with-retail-and-e-commerce-jsx": () => import("./../../../src/pages/who-we-work-with/retail-and-e-commerce.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-retail-and-e-commerce-jsx" */),
  "component---src-pages-who-we-work-with-startups-jsx": () => import("./../../../src/pages/who-we-work-with/startups.jsx" /* webpackChunkName: "component---src-pages-who-we-work-with-startups-jsx" */),
  "component---src-pages-zoho-newsletter-js": () => import("./../../../src/pages/zoho-newsletter.js" /* webpackChunkName: "component---src-pages-zoho-newsletter-js" */)
}

